import { Typography, styled } from '@mui/material'
import type { CustomTheme } from '@mui/material/styles'

const topMenuItemsCommonStyles = (theme: CustomTheme) => ({
  fontSize: '13px',
  marginLeft: theme.spacing(4),
  textDecoration: 'none',
})

export const WithTopMenuDefault = styled(Typography)(({ theme }: { theme?: CustomTheme }) => ({
  ...topMenuItemsCommonStyles(theme),
  color: theme.palette.text.topMenuSecondary,
})) as typeof Typography

export const WithTopMenuActive = styled(Typography)(({ theme }: { theme?: CustomTheme }) => ({
  ...topMenuItemsCommonStyles(theme),
  color: theme.palette.text.topMenuActive,
  fontWeight: theme.typography.fontWeightBold,
})) as typeof Typography

import { useAllPermissions } from '~/src/context/permissions/permissions'
import type { AccessPermissionPossibleTypes } from '~/src/types/menu'
import { useLoadUser } from '../session/use-load-user'

type API = (accessPermission: AccessPermissionPossibleTypes) => boolean

export const useAccessPermissionFn = (): API => {
  const { user } = useLoadUser()
  const permissions = useAllPermissions()

  return (accessPermission: AccessPermissionPossibleTypes) => {
    if (typeof accessPermission === 'function') {
      return accessPermission(user)
    }
    return !accessPermission || permissions?.[accessPermission]
  }
}

import { Box } from '@mui/material'
import type { FC, PropsWithChildren, ReactElement } from 'react'

const Container: FC<PropsWithChildren> = ({ children }): ReactElement => (
  <Box minWidth="1280px" minHeight="100vh">
    {children}
  </Box>
)

export default Container

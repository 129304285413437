import FlexBox from '@/elements/box/flex-box'
import { ButtonBase } from '@mui/material'
import NextLink from 'next/link'
import type { FC, PropsWithChildren } from 'react'
import LogoPic from '~/public/jafavicon.svg'
import Header from '../header'

const HeaderLogo: FC<PropsWithChildren> = ({ children }) => (
  <Header>
    <FlexBox width={32} height={32} ml={0.5}>
      <NextLink href="/" passHref legacyBehavior>
        <ButtonBase>
          <LogoPic />
        </ButtonBase>
      </NextLink>
    </FlexBox>
    {children}
  </Header>
)

export default HeaderLogo

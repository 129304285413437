export const ColorStrings = {
  PrimaryMain: 'primary.main',
  PrimaryDark: 'primary.dark',
  SecondaryMain: 'secondary.main',
  SecondaryDark: 'secondary.dark',
  WarningMain: 'warning.main',
  ErrorMain: 'error.main',
  ErrorDark: 'error.dark',
  TextDeepGray: 'text.deepGray',
  TextLightBlack: 'text.lightBlack',
  TextPrimary: 'text.primary',
  TextTopMenuSecondary: 'text.topMenuSecondary',
  TextTopMenuActive: 'text.topMenuActive',
  BodyContent: 'background.bodyContent',
  WarningDark: 'warning.dark',
  TextDisabled: 'text.disabled',
}

export const SchedulerColors = [
  ColorStrings.PrimaryMain,
  ColorStrings.PrimaryDark,
  ColorStrings.SecondaryMain,
  ColorStrings.SecondaryDark,
  ColorStrings.WarningMain,
  ColorStrings.WarningDark,
  ColorStrings.ErrorMain,
  ColorStrings.ErrorDark,
  ColorStrings.TextDeepGray,
]

import { useApolloClient, useMutation } from '@apollo/client'

import { useRouter } from 'next/router'
import { ROUTES } from '~/src/constants'
import logoutQuery from './logout.graphql'

const useLogout = () => {
  const apolloClient = useApolloClient()
  const router = useRouter()
  const afterLogout = async () => {
    await router.push(ROUTES.login)
    await apolloClient.clearStore()
  }

  const [logout] = useMutation(logoutQuery, {
    onCompleted: () => {
      afterLogout()
    },
    /**
     * When login data is manually erased follow to login anyway
     */
    onError: () => {
      afterLogout()
    },
    fetchPolicy: 'no-cache',
  })

  return {
    logout,
  }
}

export default useLogout

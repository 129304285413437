import HeaderComplete from '@/partials/header/header-complete/header-complete'
import Container from '@/partials/layout/container/container'
import MenuLayout from '@/partials/layout/menu-layout/menu-layout'
import Head from 'next/head'
import type { ReactElement } from 'react'
import { MenuCategories } from '~/src/config/top-menu'
import getStandardPageSSRProps from '~/src/helpers/server-side-props/get-standard-page-ssr-props'

function Home(): ReactElement {
  return (
    <>
      <Head>
        <title>Welcome to JA</title>
      </Head>
      <Container>
        <HeaderComplete activeCategory={MenuCategories.enterprise} />
        <MenuLayout submenuCategory={MenuCategories.enterprise}>Welcome to JA</MenuLayout>
      </Container>
    </>
  )
}

export const getServerSideProps = getStandardPageSSRProps(['common', 'header', 'submenu'])

export default Home

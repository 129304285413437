import { curryN } from 'ramda'
import { GTM_ATTRIBUTE, GTM_EVENT, type GtmUserPropertyType } from '~/src/constants'

export const dataGtmEvent = curryN(
  2,
  (event: GTM_EVENT, value: GtmUserPropertyType): GtmUserPropertyType => {
    const data = { [GTM_ATTRIBUTE.GTM_EVENT]: event } as GtmUserPropertyType
    Object.keys(value).forEach((el) => {
      data[el as GTM_ATTRIBUTE] = value[el as GTM_ATTRIBUTE]
    })
    return data
  },
)

const _dataGtmTableCellClick = dataGtmEvent(GTM_EVENT.TABLE_CELL_CLICK)
export const tableDataGtmEvent: (itemId: string) => GtmUserPropertyType = (itemId: string) =>
  _dataGtmTableCellClick({
    [GTM_ATTRIBUTE.GTM_ITEM_ID]: itemId,
  })

const _dataGtmButtonClick = dataGtmEvent(GTM_EVENT.BUTTON_CLICK)
export const buttonDataGtmEvent: (itemId: string) => GtmUserPropertyType = (itemId: string) =>
  _dataGtmButtonClick({
    [GTM_ATTRIBUTE.GTM_ITEM_ID]: itemId,
  })

import { Box } from '@mui/material'
import type { FC, ReactElement } from 'react'
import { type MenuCategories, getMenuConfig } from '~/src/config/top-menu'
import HeaderLogo from '../header-logo/header-logo'
import TopMenu from '../top-menu/top-menu'
import UserArea from '../user-area/user-area'

const config = getMenuConfig()

type Props = {
  activeCategory?: MenuCategories
}

const HeaderComplete: FC<Props> = ({ activeCategory }): ReactElement => (
  <HeaderLogo>
    <Box flexGrow={1} ml={5.5}>
      <TopMenu activeCategory={activeCategory} config={config} />
    </Box>
    {/*<LanguageSelect />*/}
    <UserArea />
  </HeaderLogo>
)

export default HeaderComplete

import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { filter, head, prop } from 'ramda'
import type { FC, ReactElement } from 'react'
import { getSubMenuConfig } from '~/src/config/sub-menu'
import type { MenuCategories, MenuConfigItemTopMenu } from '~/src/config/top-menu'
import { GTM_ATTRIBUTE, GTM_EVENT } from '~/src/constants'
import { dataGtmEvent } from '~/src/helpers/gtm/data-event'
import { useAccessPermissionFn } from '~/src/hooks/permissions/use-access-permission-fn'
import type { MenuConfigItem } from '~/src/types/menu'
import { WithTopMenuActive, WithTopMenuDefault } from './typography'

export type TopMenuProps = {
  activeCategory?: MenuCategories
  config: {
    items: MenuConfigItemTopMenu[]
  }
}

const TopMenu: FC<TopMenuProps> = ({ activeCategory, config }): ReactElement => {
  const { t } = useTranslation()

  const isAllowedPermission = useAccessPermissionFn()
  const subMenuConfig = getSubMenuConfig()

  const getFirstAvailableLink = (topMenuItem: MenuConfigItemTopMenu): string => {
    return pipe(
      subMenuConfig[topMenuItem.id].items,
      filter((item: MenuConfigItem) => isAllowedPermission(item.accessPermission)),
      head,
      prop('href'),
    )
  }

  return (
    <>
      {config.items.map((menuItem) => {
        const isActive = menuItem.id === activeCategory
        const href = getFirstAvailableLink(menuItem)
        const MenuItemTag = isActive ? WithTopMenuActive : WithTopMenuDefault

        /**
         * No menu item if no pages are available
         */
        if (!href) {
          return null
        }

        return (
          <NextLink href={href} key={menuItem.id} passHref legacyBehavior>
            <MenuItemTag
              component="a"
              variant="button"
              {...dataGtmEvent(GTM_EVENT.HEADER_MENU_CLICK, {
                [GTM_ATTRIBUTE.GTM_ITEM_ID]: menuItem.id.toString(),
              })}
            >
              {t(menuItem.translateKey)}
            </MenuItemTag>
          </NextLink>
        )
      })}
    </>
  )
}

export default TopMenu

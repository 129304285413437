'use client'
import { Box, Menu, MenuItem } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import { type FC, useRef, useState } from 'react'
import { useLoadUser } from '~/src/hooks/session/use-load-user'
import useLogout from './use-logout'
import UserEmail from './user-email/user-email'

const PREFIX = 'UserArea'

const menuClasses = {
  paper: `${PREFIX}-paper`,
}

const StyledMenu = styled(Menu)({
  [`& .${menuClasses.paper}`]: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    top: '0px !important',
  },
})

const BoxSxStyles = {
  cursor: 'pointer',
}

const UserArea: FC = () => {
  const theme = useTheme()
  const menuToggleRef = useRef()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { t } = useTranslation()
  const { user } = useLoadUser()
  const { logout } = useLogout()

  if (!user) {
    return null
  }

  return (
    <>
      <Box
        onClick={() => setMenuOpen(true)}
        mr={2}
        sx={BoxSxStyles}
        {...{ ref: menuToggleRef }} // Workaround (no typings for ref on Box)
      >
        <UserEmail user={user} />
      </Box>
      <StyledMenu
        anchorEl={menuToggleRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={menuClasses}
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem
          sx={{
            cursor: 'auto',
            '&:focus, &:hover': {
              backgroundColor: 'transparent',
            },
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
          }}
          disableRipple
        >
          <UserEmail user={user} />
        </MenuItem>
        {/* todo: not implemented*/}
        {/*<MenuItem>{t('header:profile-settings')}</MenuItem>*/}
        <MenuItem onClick={() => logout()}>{t('header:logout')}</MenuItem>
      </StyledMenu>
    </>
  )
}

export default UserArea

export type MenuConfigItemTopMenu = {
  id: MenuCategories
  translateKey: string
  href: string
}

export type MenuConfigTopMenu = {
  items: MenuConfigItemTopMenu[]
}

export enum MenuCategories {
  enterprise = 'enterprise',
  jackpots = 'jackpots',
  help = 'help',
  visualisation = 'visualisation',
}

const topMenuConfig = {
  items: [
    {
      id: MenuCategories.enterprise,
      translateKey: 'header:enterprise',
      href: '/companies',
    },
    {
      id: MenuCategories.jackpots,
      translateKey: 'header:jackpots',
      href: '/jackpots',
    },
    // Will not be available in MVP
    // {
    //   id: 'promo',
    //   translateKey: 'header:promo',
    //   href: '/promo',
    // },
    {
      id: MenuCategories.help,
      translateKey: 'header:help',
      href: '/about',
    },
    {
      id: MenuCategories.visualisation,
      translateKey: 'header:visualisation',
      href: '/configuration',
    },
  ],
}

export const getMenuConfig = (): MenuConfigTopMenu => topMenuConfig

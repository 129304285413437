import Avatar from '@/elements/avatar/avatar'
import { Box, Typography } from '@mui/material'
import type { FC } from 'react'

export type Props = {
  user: {
    avatarSrc?: string
    email: string
  }
}

const UserEmail: FC<Props> = ({ user }) => {
  const { avatarSrc, email } = user

  return (
    <Box display="flex" alignItems="center">
      <Avatar component={Box} mr={1} src={avatarSrc} userName={email} />
      <Typography
        variant="body2"
        sx={{
          fontSize: '13px',
        }}
      >
        {email}
      </Typography>
    </Box>
  )
}

export default UserEmail

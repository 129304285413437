import { ListItemButton, ListItemText } from '@mui/material'
import { type CustomTheme, styled } from '@mui/material/styles'

const PREFIX = 'SubMenu'

export const listItemTextClasses = {
  primary: `${PREFIX}-primary`,
}

export const StyledListItemText = styled(ListItemText)(({ theme }: { theme?: CustomTheme }) => ({
  [`& .${listItemTextClasses.primary}`]: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

export const primaryListItemClasses = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
}

export const StyledPrimaryListItemButton = styled(ListItemButton)(
  ({ theme }: { theme?: CustomTheme }) => ({
    [`&.${primaryListItemClasses.root}`]: {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.palette.text.leftMenuItem,
      paddingRight: 0,
      paddingLeft: theme.spacing(1.5),
    },
    [`&.${primaryListItemClasses.selected}`]: {
      borderLeft: `3px solid ${theme.palette.primary.dark}`,
      color: theme.palette.primary.dark,
      backgroundColor: `${theme.palette.common.white} !important`,
      // Consider active item highlight left border
      paddingLeft: `calc(${theme.spacing(1.5)} - 3px)`,
    },
  }),
) as unknown as typeof ListItemButton

import ContentMinHeight from '@/elements/layout/content-min-height'
import SubMenu from '@/partials/sub-menu/sub-menu'
import { Box, type CustomTheme, useTheme } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import type { MenuCategories } from '~/src/config/top-menu'
import { ColorStrings } from '~/src/constants/colors'

export type Props = PropsWithChildren<{
  submenuCategory: MenuCategories
}>

const MenuLayout: FC<Props> = ({ submenuCategory, children }) => {
  const theme = useTheme<CustomTheme>()
  return (
    <ContentMinHeight
      p={2}
      pt={theme.constants.layoutPaddingTopStandard}
      pb={theme.constants.layoutPaddingBottomStandard}
      pl={0}
      bgcolor={ColorStrings.BodyContent}
      display="grid"
      gridTemplateColumns={`${theme.constants.leftMenu.width} 1fr`}
    >
      <SubMenu category={submenuCategory} />
      <Box>{children}</Box>
    </ContentMinHeight>
  )
}

export default MenuLayout
